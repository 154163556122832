/*
 * @fileOverview Table module definition
 */

import View from 'views/View';
/**
 * Sets up tables for TableView
 *
 * @class TableView
 */
export default class TableView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {SearchView}
   * @public
   * @chainable
   */
  createChildren () {
    //this.$element = this.$element.find('table');

    this.headers = [];
    this.needsHeaderRow = false;
    this.$firstRow = null;

    return this;
  }

  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit () {
    this.applyResponsiveHeaderAttributes();
    this.createHeaderRow();
    return this;
  }
  /*
  * Loop across all rows and all cells and apply the appropriate header label
  * to be used on the responsive table: https://codepen.io/geoffyuen/pen/FCBEg
  *
  * @property applyResponsiveHeaderAttributes
  * @returns {void}
  * @public
  */
  applyResponsiveHeaderAttributes() {
    //let's loop through any rows and cells and set up for responsiveness
    const rows = this.$element.find('tr');
    rows.toArray().forEach(this.updateRow.bind(this));
  }

  /*
  * Find cells and update them
  *
  * @property updateRow
  * @returns {void}
  * @public
  */
  updateRow(row, rowIdx) {
    const $row = $(row);

    if (rowIdx === 0) {
      this.$firstRow = $row;
      this.findHeaderLabels($row);
    } else {
      $row.find('td').toArray().forEach(this.updateCell.bind(this));
    }
  }

  /*
  * Add an attribute (data-th) to the cell reflecting the column header
  *
  * @property updateCell
  * @returns {void}
  * @public
  */
  updateCell(td, idx) {
    const $td = $(td);
    $td.attr('data-th', this.headers[idx]);
    $td.wrapInner('<div class="table-td table-td_right"></div>');
  }

  /*
  * If the first row is not a bunch of <th> elements, then we need to create them
  *
  * @property findHeaderLabels
  * @returns {void}
  * @public
  */
  createHeaderRow($row) {
    if (this.needsHeaderRow) {
      this.$firstRow.empty();
      // We make a TH _and_ apply tabindex styles to it.
      this.headers.forEach((header) => {
        this.$firstRow.append(`<th tabindex=\"0\">${header}</th>`);
      });
    }
  }

  /*
  * Finds the header cells if they are there (th) or uses the first row as header row.
  * After finding the header row or first row, we store the values/labels to use on the mobile view.
  *
  * @property findHeaderLabels
  * @returns {void}
  * @public
  */
  findHeaderLabels($row) {
    let tableHeaders = $row.find('th');
    if (tableHeaders.length === 0) {
      this.needsHeaderRow = true;
    }
    // We add tabindex to existing table headers.
    else {
      tableHeaders.each(function () { $(this).attr('tabindex', 0); });
    }

    tableHeaders = $row.find('td').toArray();
    this.headers = tableHeaders.map((header) => {
      return $(header).text();
    })
  }
}
