/*
 * @fileOverview CookieService
 */

const CookieService = {
  getCookie(name) {
    const match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (match) return match[1];
  },

  /**
  * Stores information related to a item as a cookie.
  * Expiration is based on hours. 
  * Multiply your hours value by 24 if using days.
  *
  * @method setItem
  * @param {String} name [The item's cookie name]
  * @param {String} value [The item's value string]
  * @param {Number} hours [The item's expiry in hours]
  * @param {Boolean} strict [Sets the cookie to Strict/Secure if true, Lax if false]
  */
  setCookie(name, value, hours, strict) {
    var expires = "";
    var sameSite = "Lax";
    if (strict) {
      sameSite = "Strict; Secure";
    }
    if (hours) {
        var date = new Date();
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/;SameSite=" + sameSite;
  }
}

export default CookieService;
