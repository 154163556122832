import View from 'views/View';
import Utils from 'utils/Utils';

const EVENTS = {
  'SCROLL': 'scroll',
  'RESIZE': 'resize'
};

const TIMES = {
  'THROTTLE': 1.66666667, // I mean, it's close to 60 FPS
  'PARALLAX_RATE': .25
};

const DATA = {
  'PARALLAX_RATE': 'parallax-rate'
}

export default class HeroBlockView extends View {
  constructor($element) {
    super($element);
  }

  createChildren() {
    this.$window = $(window);
    this.$imageContainer = this.$element.find('.hero-block_image');

    return this;
  }

  enable() {
    //if the element has the parallax class, then we need to setup the parallax effect.
    //if the image is set to the bottom, then this is pure CSS, so we don't need to do anything.
    if (this.hasParallax()) {
      //setup initial state
      this.$rate = this.$element.data(DATA.PARALLAX_RATE) ?? TIMES.PARALLAX_RATE;
      this.$xAttachment = this.getImageAttachmentX();
      this.$yAttachment = this.getImageAttachmentY();

      this.$window.on(EVENTS.SCROLL, Utils.throttle(this.handleScroll.bind(this), TIMES.THROTTLE));
      this.$window.on(EVENTS.RESIZE, Utils.throttle(this.handleScroll.bind(this), TIMES.THROTTLE));
    }

    return this;
  }

  afterInit() {
    if (this.hasParallax()) {
      this.handleScroll();
    }
  }

  handleScroll() {
    if (this.calculateInViewport()) {
      this.$imageContainer[0].style.backgroundPosition = `${this.$xAttachment} calc(${this.calculateParallax()}px + ${this.$yAttachment})`;
    } else {
      this.$imageContainer[0].style.backgroundPosition = '';
    }
  }

  hasParallax() {
    if (this.elementHasClass('hero-block--no-image'))
      return false;

    return this.elementHasClass('hero-block--parallax');
  }

  calculateParallax() {
    const scrolled = window.scrollY;
    const topOffset = this.$imageContainer.offset().top;

    return Math.round(this.$rate * (scrolled - topOffset));
  }

  calculateInViewport() {
    const scrolled = window.scrollY;
    const topOffset = this.$imageContainer.offset().top;
    const bottomOffset = topOffset + this.$imageContainer.outerHeight();

    //if the top is below the vertical scroll position, then it's not yet in view.
    if (scrolled < topOffset)
      return false;

    //if the bottom is above the vertical scroll position, then it's not in view. Adding a 20px padding to prevent it from flashing when it scrolls into position.
    if (scrolled > bottomOffset + 20)
      return false;

    return true;
  }

  //This will be the first parameter of the CSS background-position;
  getImageAttachmentX() {
    if (this.elementHasClass("hero-block--image-left"))
      return 'left';

    if (this.elementHasClass("hero-block--image-right"))
      return 'right';

    return 'center';
  }

  //This will be the vertical parameter of the CSS background position, as a percent.
  getImageAttachmentY() {
    if (this.elementHasClass("hero-block--image-top"))
      return '0%';

    if (this.elementHasClass("hero-block--image-bottom"))
      return '100%';

    return '50%';
  }

  elementHasClass(className) {
    return this.$element[0].classList.contains(className);
  }
}
