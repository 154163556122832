import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import App from './App';
import PubSub from './utils/PubSub';

document.addEventListener('DOMContentLoaded', function () {
    window.app = new App();
});

//Open to other solutions, but we need to support Multiple maps on a page.
window.pubsub = new PubSub();

window.onMapsApiLoaded = function() {
  window.pubsub.publish('mapApiLoaded');
}
