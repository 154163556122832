/*
 * @fileOverview MarriottTracking module definition
 */

import CookieService from 'services/CookieService';

const MarriottTracking = {

  /**
   * [rewriteTrackingUrls checks for scid tracking code that was captured in the backend and placed in cookie, if present find the domains to rewrite and iterate]
   * @return {[type]} [description]
   */
  rewriteTrackingUrls() {
    const scid = CookieService.getCookie('scid');

    if (scid && window.marriottTrackingDomains && window.marriottTrackingDomains.length > 0) {
      window.marriottTrackingDomains.forEach((item) => this.rewriteForDomain(item, scid));
    }
  },

/**
 * [rewriteForDomain uses domain written to the window from app.config to search for urls tp rewrite for Marriott tracking]
 * example: //https://www.marriott.com/setSCtracking.mi?scid=3934b4e6-32c1-4c01-bbb6-70683885ee9d&ppc=pcc&mid=/specials/mesOffer.mi?marrOfferId=1220515&displayLink=true
 * @param  {string} domain [url or part of url to search match against]
 * @return {void}
 */
  rewriteForDomain(domain, scid) {
    $(`a[href*="${domain}"]`).each((idx, item) => {
      var urlForQuery = item.pathname + (item.search.indexOf('?') > -1 ? item.search + "&scid=" + scid : item.search + "?scid=" + scid);
      const newUrl = `https://${item.hostname}${urlForQuery}`;
      item.href = newUrl;
    });
  }
}
export default MarriottTracking;
