/*
 * @fileOverview Utils module definition
 */

/**
 * Exposes utility functions
 *
 * @util Debounce
 */
const Utils = {
  /**
   * Fires a function at the end of a burst of events
   *
   * @param  {Function} fn    [Function to be fired]
   * @param  {Number}   delay [The delay in ms until function is fired]
   * @return {Function}       [debounce function]
   */
  debounce(fn, delay = 200) {
    let timer = null;

    return function () {
      let context = this,
        args = arguments;

      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  },

  /**
   * Fires a function once every {threshold time} and also
   * on the first and last times.
   *
   * @param  {Function} fn         [Function to be fired]
   * @param  {Number}   threshhold [Threshold for firing again]
   * @param  {Object}   scope      [context]
   * @return {Function}            [throttle function]
   */
  throttle(fn, threshhold = 200, scope) {
    var last,
      deferTimer;
    return function () {
      var context = scope || this;

      var now = +new Date,
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(() => {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  },

  isIphone() {
    return navigator.userAgent.match(/(iPhone|iPod)/i);
  },

  isIpad() {
    return navigator.userAgent.match(/iPad/i);
  },

  isIosSafari() {
    const ua = navigator.userAgent;
    const isIos = this.isIphone() || this.isIpad();
    const isSafari = ua.match(/AppleWebKit/i) && !ua.match(/(Chrome|CrIOS)/i);

    return isIos && isSafari;
  },

  /**
   * Check is window size matches desktop breakpoint.
   *
   * @method isDesktopViewport
   * @public
   */
  isDesktopViewport() {
    return window.matchMedia('(min-width: 767px)').matches;
  },

  /**
   * Check is window size matches large desktop breakpoint.
   *
   * @method isLargeDesktopViewport
   * @public
   */
  isLargeDesktopViewport() {
    return window.matchMedia('(min-width: 991px)').matches;
  },

  /**
   * Javascript implementation of Java’s String.hashCode method
   *
   * @method hashCode
   * @param {String} str
   * @return {Int} 32 bit integer unique to the string
   */
  hashCode(str) {
    let hash = 0,
      i, chr, len;

    str = str + '';

    if (str.length === 0) return hash;
    for (i = 0, len = str.length; i < len; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },

  /**
   * [isTab return true if the event is a tab event]
   * @param  {[type]}  event [keydown devent]
   * @return {Boolean}   [true or false]
   */
  isTab(event) {
    return (event && event.keyCode === 9 && !event.shiftKey);
  },

  /**
   * [isTabBack return true if shoft tab was pressed or tab back]
   * @param  {[type]}  event [keydown devent]
   * @return {Boolean}   [true or false]
   */
  isTabBack(event) {
    return event && event.shiftKey && event.keyCode === 9;
  },

  /**
   * [removeSwipeDetect description]
   * @param  {Object} el [DOM element]
   * @return {void}
   */
  removeSwipeDetect(el, callback) {
    el.removeEventListener('touchstart', callback);
    el.removeEventListener('touchend', callback);
  },
  /**
   * [decodeHtml returns sanitized html and prevents XSS attacks]
   * @param {any} html
   */
  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  },
  /**
   * Opens up the necessary event listeners on an element
   * to detect touch movement and then returns the direction
   * of that movement to the event handler
   *
   * @param {Object} el [DOM element]
   * @param {function} callback [The function receiving direction]
   * @param {bool} preventDefault [option to prevent the default behavior]
   */
  swipeDetect(el, callback) {
    let touchsurface = el,
      swipedir,
      startX,
      startY,
      distX,
      distY,
      threshold = 10,
      restraint = 100,
      allowedTime = 300,
      elapsedTime,
      startTime,
      handleswipe = callback;

    const velocityThreshold = threshold / allowedTime;

    touchsurface.addEventListener('touchstart',
      function (e) {
        let touchobj = e.changedTouches[0];
        swipedir = 'none';
        startX = touchobj.pageX;
        startY = touchobj.pageY;
        startTime = new Date().getTime();
      },
      false);

    touchsurface.addEventListener('touchend',
      function (e) {
        let touchobj = e.changedTouches[0];
        distX = touchobj.pageX - startX;
        distY = touchobj.pageY - startY;
        elapsedTime = new Date().getTime() - startTime;
        const velocity = distX / elapsedTime;

        if (Math.abs(velocity) > velocityThreshold) {
          if (Math.abs(distX) >= threshold) {
            swipedir = (distX < 0) ? 'left' : 'right';
          } else if (Math.abs(distY) >= threshold) {
            swipedir = (distY < 0) ? 'up' : 'down';
          }
        }
        handleswipe(swipedir);
      },
      false);
  },

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },

  isFunction(func) {
    return typeof func === 'function';
  }
}

export default Utils;
