/*
 * @fileOverview Application Controller definition
 */
// setting up the basePath
if (window.resourceBasePath) {
  __webpack_public_path__ = window.resourceBasePath;
}

//import GsapLoader from 'views/GsapLoader';
import HiddenButFocusableView from 'views/HiddenButFocusableView';
import MarriottTracking from 'services/MarriottTracking';
import NavView from 'views/NavView';
import QuarantineView from 'views/QuarantineView';
import Utils from 'utils/Utils';

//new components
import AnchorLinksView from 'views/AnchorLinksView';
import './components/Navis';
import './components/TimeCircles';
import HeroBlockView from 'views/HeroBlockView';

/**
 * Initial application setup. Runs once on every page load.
 *
 * @class App
 * @constructor
 */
export default class App {
  // Creates modules base on the data-module attribute
  constructor() {
    this.dataModules = {
      //'gsap-loader': GsapLoader,
      'hiddenButFocusable': HiddenButFocusableView,
      'nav': NavView,
      'quarantine': QuarantineView
    };

    this.dynamicModules = [
      'accordion',
      'activity-carousel',
      'amadeus-form',
      'ambient-video',
      'booking',
      'booking-form',
      'booking-nav',
      'carousel',
      'cropless-carousel',
      'darkskyicon',
      'dining-menu',
      'dropdown',
      'dual-datepicker',
      'expandable-blocks',
      'featuredDropdown',
      'formDropdown',
      'filter',
      'form',
      'gallery',
      'garage-door',
      'gsap-loader',
      'guest-number-picker',
      'hero-block',
      'hero-crossfade',
      'hero-scrolling',
      'left-hand-nav',
      'map-single-location',
      'modal',
      'navis-form',
      'newsletter-form',
      'product-comparison',
      'promo-code-picker',
      'product-details',
      'product-details-v2',
      'scrollable-hList',
      'searchable-results',
      'search',
      'single-datepicker',
      'timeline-2025',
      'units-toggle',
      'tabs',
      'trails-filter',
      'toaster',
      'youtube'
    ];

    this.controllerClasses = {};

    this.controllers = [];

    this.init();
  }

  /**
   * Initializes the application and kicks off loading of prerequisites.
   *
   * @method init
   * @public
   */
  init() {
    this.initViewComponents();
    this.initDynamicImports();
    this.onApplicationStart();
    // this.initControllers();
  }

  /**
   * Exposes a kick-off method for jQuery components
   * Creates moduleNameViews Array if not already created
   * Creates instance of view per module
   *
   * @method initViewComponents
   * @public
   */
  initViewComponents() {
    Object.keys(this.dataModules).forEach(name => {
      const $modules = $(`[data-module="${name}"]`);

      $modules.each((index, el) => {
        const viewName = `${name}Views`;

        if (!this[viewName]) {
          this[viewName] = [];
        }

        this[viewName][index] = new this.dataModules[name](el);
      });
    });
  }

  /**
   * Exposes a kick-off method to dynamically import 
   * modules based on the presence of their elements on 
   * the page. 
   * 
   * @method initDynamicImports
   * @public
   */
  initDynamicImports() {
    //eslint-disable-next-line complexity
    this.dynamicModules.forEach(name => {
      const $modules = $(`[data-module="${name}"]`);

      if ($modules.length > 0) {
        switch (name) {
          case "accordion":
            import(/*webpackChunkName:"Accordion"*/`views/AccordionView`)
              .then(({ default: AccordionView }) => {
                this.startDynamicImport(AccordionView, $modules, name);
              });
            break;
          case "activity-carousel":
            import(/*webpackChunkName:"ActivityCarousel"*/`views/ActivityCarouselView`)
              .then(({ default: ActivityCarouselView }) => {
                this.startDynamicImport(ActivityCarouselView, $modules, name);
              });
            break;
          case "amadeus-form":
            import(/*webpackChunkName:"AmadeusForm"*/`views/AmadeusFormView`)
              .then(({ default: AmadeusFormView }) => {
                this.startDynamicImport(AmadeusFormView, $modules, name);
              });
            break;
          case 'ambient-video':
            import(/*webpackChunkName:"AmbientVideo"*/`views/AmbientVideoView`)
              .then(({ default: AmbientVideoView }) => {
                this.startDynamicImport(AmbientVideoView, $modules, name);
              });
            break;
          case 'booking':
            import(/*webpackChunkName:"BookingWidget"*/`views/BookingWidgetView`)
              .then(({ default: BookingWidgetView }) => {
                this.startDynamicImport(BookingWidgetView, $modules, name);
              });
            break;
          case 'booking-form':
            import(/*webpackChunkName:"BookingForm"*/`views/BookingFormView`)
              .then(({ default: BookingFormView }) => {
                this.startDynamicImport(BookingFormView, $modules, name);
              });
            break;
          case 'booking-nav':
            import(/*webpackChunkName:"BookingNav"*/`views/BookingNavView`)
              .then(({ default: BookingNavView }) => {
                this.startDynamicImport(BookingNavView, $modules, name);
              });
            break;
          case 'carousel':
            import(/*webpackChunkName:"BookingNav"*/`views/CarouselView`)
              .then(({ default: CarouselView }) => {
                this.startDynamicImport(CarouselView, $modules, name);
              });
            break;
          case 'cropless-carousel':
            import(/*webpackChunkName:"CroplessCarousel"*/`views/CroplessCarouselView`)
              .then(({ default: CroplessCarouselView }) => {
                this.startDynamicImport(CroplessCarouselView, $modules, name);
              });
            break;
          case 'darkskyicon':
            import(/*webpackChunkName:"DarkSkyIcon"*/`views/DarkSkyIconView`)
              .then(({ default: DarkSkyIconView }) => {
                this.startDynamicImport(DarkSkyIconView, $modules, name);
              });
            break;
          case 'dining-menu':
            import(/*webpackChunkName:"DiningMenu"*/`views/DiningMenuView`)
              .then(({ default: DiningMenuView }) => {
                this.startDynamicImport(DiningMenuView, $modules, name);
              });
            break;
          case 'dropdown':
            import(/*webpackChunkName:"Dropdown"*/`views/dropdowns/DropdownView`)
              .then(({ default: DropdownView }) => {
                this.startDynamicImport(DropdownView, $modules, name);
              });
            break;
          case 'dual-datepicker':
            import(/*webpackChunkName:"Datepicker"*/`views/DualDatepickerView`)
              .then(({ default: DualDatepickerView }) => {
                this.startDynamicImport(DualDatepickerView, $modules, name);
              });
            break;
          case 'expandable-blocks':
            import(/*webpackChunkName:"ExpandableBlocks"*/`views/ExpandableBlocksView`)
              .then(({ default: ExpandableBlocksView }) => {
                this.startDynamicImport(ExpandableBlocksView, $modules, name);
              });
            break;
          case 'featuredDropdown':
            import(/*webpackChunkName:"FeaturedDropdown"*/`views/dropdowns/FeaturedDropdownView`)
              .then(({ default: FeaturedDropdownView }) => {
                this.startDynamicImport(FeaturedDropdownView, $modules, name);
              });
            break;
          case 'formDropdown':
            import(/*webpackChunkName:"FormDropdown"*/`views/dropdowns/FormDropdownView`)
              .then(({ default: FormDropdownView }) => {
                this.startDynamicImport(FormDropdownView, $modules, name);
              });
            break;
          case 'filter':
            import(/*webpackChunkName:"Filter"*/`views/FilterView`)
              .then(({ default: FilterView }) => {
                this.startDynamicImport(FilterView, $modules, name);
              });
            break;
          case 'form':
            import(/*webpackChunkName:"Form"*/`views/FormView`)
              .then(({ default: FormView }) => {
                this.startDynamicImport(FormView, $modules, name);
              });
            break;
          case 'gallery':
            import(/*webpackChunkName:"Gallery"*/`views/GalleryView`)
              .then(({ default: GalleryView }) => {
                this.startDynamicImport(GalleryView, $modules, name);
              });
            break;
          case 'garage-door':
            import(/*webpackChunkName:"GarageDoor"*/`views/GarageDoor`)
              .then(({ default: GarageDoorView }) => {
                this.startDynamicImport(GarageDoorView, $modules, name);
              });
            break;
          case 'gsap-loader':
            import(/*webpackChunkName:"GsapLoader"*/`views/GsapLoader`)
              .then(({ default: GsapLoader }) => {
                this.startDynamicImport(GsapLoader, $modules, name);
              });
            break;
          case 'guest-number-picker':
            import(/*webpackChunkName:"GuestNumber"*/`views/GuestNumberView`)
              .then(({ default: GuestNumberView }) => {
                this.startDynamicImport(GuestNumberView, $modules, name);
              });
            break;
          case 'hero-block':
            import(/*webpackChunkName:"HeroBlock"*/`views/HeroBlockView`)
              .then(({ default: HeroBlockView }) => {
                this.startDynamicImport(HeroBlockView, $modules, name);
              });
            break;
          case 'hero-crossfade':
            import(/*webpackChunkName:"HeroCrossfade"*/`./components/HeroCrossfade`);
            break;
          case 'hero-scrolling':
            import(/*webpackChunkName:"HeroScrolling"*/`views/HeroScrollingView`)
              .then(({ default: HeroScrollingView }) => {
                this.startDynamicImport(HeroScrollingView, $modules, name);
              });
            break;
          case 'left-hand-nav':
            import(/*webpackChunkName:"LeftHandNav"*/`views/LeftHandNavView`)
              .then(({ default: LeftHandNavView }) => {
                this.startDynamicImport(LeftHandNavView, $modules, name);
              });
            break;
          case 'map-single-location': 
            import(/*webpackChunkName:"SingleLocationMapNav"*/`views/SingleLocationMapView`)
              .then(({ default: SingleLocationMapView }) => {
                this.startDynamicImport(SingleLocationMapView, $modules, name);
              });
            break;
          case 'modal':
            import(/*webpackChunkName:"Modal"*/`views/ModalView`)
              .then(({ default: ModalView }) => {
                this.startDynamicImport(ModalView, $modules, name);
              });
            break;
          case 'navis-form':
            import(/*webpackChunkName:"NavisForm"*/`views/NavisFormView`)
              .then(({ default: NavisFormView }) => {
                this.startDynamicImport(NavisFormView, $modules, name);
              });
            break;
          case 'newsletter-form':
            import(/*webpackChunkName:"NewsletterForm"*/`views/NewsletterFormView`)
              .then(({ default: NewsletterFormView }) => {
                this.startDynamicImport(NewsletterFormView, $modules, name);
              });
            break;
          case 'product-comparison':
            import(/*webpackChunkName:"ProductComparison"*/`views/ProductComparisonView`)
              .then(({ default: ProductComparisonView }) => {
                this.startDynamicImport(ProductComparisonView, $modules, name);
              });
            break;
          case 'product-details':
            import(/*webpackChunkName:"ProductDetails"*/`views/ProductDetailsView`)
              .then(({ default: ProductDetailsView }) => {
                this.startDynamicImport(ProductDetailsView, $modules, name);
              });
            break;
          case 'product-details-v2':
            import(/*webpackChunkName:"ProductDetails"*/`views/ProductDetailsV2View`)
              .then(({ default: ProductDetailsV2View }) => {
                this.startDynamicImport(ProductDetailsV2View, $modules, name);
              });
            break;
          case 'promo-code-picker':
            import(/*webpackChunkName:"PromoCodePicker"*/`views/PromoCodePickerView`)
              .then(({ default: PromoCodePickerView }) => {
                this.startDynamicImport(PromoCodePickerView, $modules, name);
              });
            break;
          case 'scrollable-hList':
            import(/*webpackChunkName:"ScrollableHList"*/`views/ScrollableHList`)
              .then(({ default: ScrollableHList }) => {
                this.startDynamicImport(ScrollableHList, $modules, name);
              });
            break;
          case 'searchable-results':
            import(/*webpackChunkName:"SearchableResults"*/`views/SearchableResults`)
              .then(({ default: SearchableResults }) => {
                this.startDynamicImport(SearchableResults, $modules, name);
              });
            break;
          case 'search':
            import(/*webpackChunkName:"Search"*/`views/SearchView`)
              .then(({ default: SearchView }) => {
                this.startDynamicImport(SearchView, $modules, name);
              });
            break;
          case 'single-datepicker':
            import(/*webpackChunkName:"Datepicker"*/`views/SingleDatepickerView`)
              .then(({ default: SingleDatepickerView }) => {
                this.startDynamicImport(SingleDatepickerView, $modules, name);
              });
            break;
          case 'tabs':
            import(/*webpackChunkName:"Tabs"*/`views/TabsView`)
              .then(({ default: TabsView }) => {
                this.startDynamicImport(TabsView, $modules, name);
              });
            break;
          case 'timeline-2025':
            import(/*webpackChunkName:"Timeline2025"*/`views/Timeline2025`)
              .then(({ default: Timeline2025View }) => {
                this.startDynamicImport(Timeline2025View, $modules, name);
              });
            break;
          case 'units-toggle':
            import(/*webpackChunkName:"UnitsToggle"*/`views/UnitsToggleView`)
              .then(({ default: UnitsToggleView }) => {
                this.startDynamicImport(UnitsToggleView, $modules, name);
              });
            break;
          case 'trails-filter':
            import(/*webpackChunkName: "TrailsFilter"*/`views/TrailsFilterView`)
              .then(({ default: TrailsFilterView }) => {
                this.startDynamicImport(TrailsFilterView, $modules, name);
              })
            break;
          case 'toaster':
            import(/*webpackChunkName:"Toaster"*/`views/ToasterView`)
              .then(({ default: ToasterView }) => {
                this.startDynamicImport(ToasterView, $modules, name);
              });
            break;
          case 'youtube':
            import(/*webpackChunkName:"Youtube"*/`views/YoutubeView`)
              .then(({ default: YoutubeView }) => {
                this.startDynamicImport(YoutubeView, $modules, name);
              });
            break;
        }
      }
    });
  }

  startDynamicImport(moduleFunction, $modules, name) {
    $modules.each((index, el) => {
      if (!this[`${name}Views`]) {
        this[`${name}Views`] = [];
      }

      this[`${name}Views`][index] = new moduleFunction(el);
    });
  }

  /**
   * Exposes a kick-off method for Controllers
   * An element assigned to a Controller must have a data-controller="" attr
   * It must also have a data-child="" attr to let the controller know its view
   *
   * @method initControllers
   * @public
   */
  initControllers() {
    Object.keys(this.controllerClasses).forEach(name => {
      const children = [];

      $(`[data-controller="${name}"]`).each((index, el) => {
        children.push($(el));
      });

      this.controllers[name] = new this.controllerClasses[name](children);
    });
  }

  onApplicationStart() {
    if (Utils.getParameterByName('resetLocalStorage') === 'true') {
      localStorage.clear()
    }

    MarriottTracking.rewriteTrackingUrls();
    AnchorLinksView();
  }
}
