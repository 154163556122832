/*
 * File Overview: Reusable browser scrolling functions.
 * 
 * Notes:
 * - Magic Number: Scroll Time is set to 1000ms.
 * - Scrolling uses a recursive function that will call itself until the total time is met. Should provide a smooth scrolling experience.
 */

import * as AnimationMath from './AnimationMath';
import Utils from './Utils';

const defaults = {
  scrollTime: 1000
};

const scrollToElement = (elementId, offset = 0) => {
  const element = document.getElementById(elementId);
  if (!element) return;

  //starting position
  const positionStart = window.scrollY;

  //ending position
  const positionFinal = element.getBoundingClientRect().top + positionStart;

  //to be multiplied by the elapsed MS to get the current position
  const totalDistance = positionFinal - positionStart;

  let start;

  const step = (timestamp) => {
    if (start === undefined)
      start = timestamp;

    let elapsed = timestamp - start;
    let percentOfTime = elapsed / defaults.scrollTime;

    if (percentOfTime < 1) {
      let percentOfPosition = AnimationMath.easeInOutQuad(percentOfTime);
      window.scrollTo(0, (positionStart + (totalDistance * percentOfPosition)) - offset);

      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, positionFinal - offset);

      // Focus helpers adapted from https://github.com/openfed/kiso/pull/49/files
      // Set focus if we can.
      element.focus();

      // Check if the target was focused.
      if (document.activeElement === element) {
        return;
      }
      // Add tabindex as a workaround if not.
      element.tabIndex = -1;
      // Then set focus again.
      element.focus();
    }
  };

  window.requestAnimationFrame(step);
}

const scrollToTrigger = ($trigger) => {
  var triggerPosition = $trigger.offset().top;

  var offset = 0;
  if ($('.js-bookingForm').length > 0) {
    offset = $('.js-bookingForm').outerHeight();
    if (offset === 0 && Utils.isLargeDesktopViewport()) {
      // Assuming we're at the top of a page, add the default height, ~88px.
      offset = 88;
    }
  }
  else if ($('.js-stickyNavbar').length > 0) {
    offset = $('.js-stickyNavbar').outerHeight();
  }

  $('html, body').animate({
    'scrollTop': triggerPosition - offset
  });

  // Focus helpers adapted from https://github.com/openfed/kiso/pull/49/files
  $trigger.focus();

  // Checking if the target was focused.
  if ($trigger.is(':focus')) {
    return;
  }
  // Adding tabindex for elements not focusable.
  $trigger.attr('tabindex', '-1');
  // Setting focus...
  $trigger.focus();
}

export { scrollToElement, scrollToTrigger };
