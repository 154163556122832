/*
 * File Overview: Looks for links with anchors on a page, and if they have a corresponding action, will trigger the appropriate actions.
 *
 * Notes:
 * - This file needs to run automatically at pageload
 * - If a link has an anchor, decide if it has a match on the page
 * - For modals it needs to open the modal
 * - For on page anchors, it needs to scroll to that location
 * - If the anchor doesn't have a corresponding element, then do nothing
 */

import { scrollToElement } from '../utils/Scroll';
import Utils from '../utils/Utils';

const AnchorLinksView = () => {
  const anchorLinks = getAnchorLinks();
  const path = location.pathname;

  anchorLinks.forEach((element) => {
    let target = getTargetElement(element);
    let link = element.getAttribute(`href`);

    // If there is a returned element, this will be truthy
    // Second, we check if the link starts with an anchor or the pathname
    // We want to avoid /pass#faq-1 from blocking /pass/sister#faq-1 from working
    // We also want legacy or built-in anchors to work as intended with a simple /#
    if (target && (link.startsWith("#") || link.startsWith(path))) {
      element.addEventListener(`click`, (e) => {
        e.stopPropagation();
        e.preventDefault();
        var offset = 0;
        if ($('.js-bookingForm').length > 0) {
          offset = $('.js-bookingForm').outerHeight();
          if (offset === 0 && Utils.isLargeDesktopViewport()) {
            // Assuming we're at the top of a page, add the default height, ~88px.
            offset = 88;
          }
        }
        else if ($('.js-stickyNavbar').length > 0) {
          offset = $('.js-stickyNavbar').outerHeight();
        }
        scrollToElement(target.getAttribute('id'), offset);
      });
    }
  });
};

const getAnchorLinks = () => {
  return document.querySelectorAll(`a[href*="#"]`);
};

const getTargetElement = (element) => {
  const targetID = element.getAttribute(`href`).split(`#`).pop();
  return document.getElementById(targetID);
}
export default AnchorLinksView;
export { getAnchorLinks, getTargetElement };
