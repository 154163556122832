/*
 * File Overview: Math functions for use with animations. 
 * 
 * These functions work by providing time(x) as a decimal of total time (0 to 1) and getting a percentage of total change (i.e. percentage of progress towards final coordinates)
 * 
 * Math can be found at https://easings.net
 */

const easeInOutQuad = (x) => {
  //if x is not a number or out of th bounds of 0 to 1, return undefined
  if (typeof (x) !== "number" || x < 0 || x > 1) {
    return undefined;
  }

  return x < .5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
};

export { easeInOutQuad };
